.schedule-table-cust table td{
    /* background: #f2f8ff; */
    padding: 8px 15px;
}
.schedule-date-tab .react-date-picker__wrapper {
    border: none;
    padding: 5px;
    border-radius: 4px;
}
table.table.table-bordered td {
    padding: 8px 15px;
}
.schedule-date-tab  svg.react-date-picker__calendar-button__icon.react-date-picker__button__icon {
    stroke: #c9cbcc !important;
}

.schedule-date-tab button.react-date-picker__clear-button.react-date-picker__button {
    color: #c9cbcc !important;
    stroke: #c9cbcc !important;
}

.schedule-date-tab svg.react-date-picker__clear-button__icon.react-date-picker__button__icon {
    stroke: #c9cbcc !important;
}

.schedule-date-tab .react-date-picker.react-date-picker--closed.react-date-picker--enabled {
    width: 100%;
}

.schedule-datetime-tab {
    border: 1px solid #e6e9ec !important;
    padding: 5px;
    border-radius: 4px;
    width: 100%;
    font-size: 15px;
}
