/********** Common Style ************/

.selectdec {
  border: 1px solid #e6e9ec!important;
  font-size: 14px;
  color: #8b8b8b !important;
  border-radius: 4px;
  margin-top: 2px;
  outline: none !important;
  box-shadow: none !important;
  padding: 10px !important;
  height: auto !important;
}

.inputDec {
  border: 1px solid #d5d5d5;
  padding: 10px;
  border-radius: 4px;
  height: 39px;
}

.btnRight{
  float: right;
}

/********** SideBar Style ************/

.sideBar {
  display: none;
  align-items: left;
}

.menu-icon {
  font-size: 1rem;
  line-height: 1;
  margin-right: 0.625rem;
  color: #676767;
}

/********** LoginCard Style ************/

.textDec {
  font-size: 14px;
  font-weight: bold;
}

.otpText {
  text-decoration: none;
  font-weight: bold;
}

/********** ResultCard Style ************/

.verifyTxt {
  float: right;
  margin-top: 10px;
  font-size: 14px;
}

.subBtn {
  float: right;
}

/********** CarouselCard Style ************/

.blueCard {
  background: #007bff;
  color: #ffff;
}

.whiteBox {
  background: #ffff;
  border-radius: 5px;
}

.numberTxt {
  color: black;
  font-size: 19px;
  font-weight: 900;
}

.iconCrown{
  opacity: 0.3;
}



@media (max-width:768px){

  .modalInput{
    margin-top: 20px;
  }

}
/********* Login Style ***********/

.input-group-text1 {
  display: flex;
  align-items: center;
  padding: 0.875rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  align-items: center;

}